.verify2fa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f6f9;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .verify2fa h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .verify2fa input {
    width: 250px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
  }
  .codeInput {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    input {
      width: 40px;
      height: 50px;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      border: 1px solid #ccc;
      border-radius: 8px;
      outline: none;
      transition: all 0.3s ease-in-out;
      font-family: "Nunito Sans";

      &:focus {
        border-color: #4caf50;
        box-shadow: 0px 0px 5px rgba(76, 175, 80, 0.5);
      }
    }
  }
  
  .verify2fa button {
    margin-top: 15px;
    width: 250px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .confirmButton  {
              display: block;
              width: 150px;
              padding: 10px;
              border-radius: 4px;
              background: #454748;
              color: white;
              border: none;
              font-size: 16px;
              font-weight: bold;
              cursor: pointer;
              transition: 0.3s;
           
          
            &:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }
  }
  
  
  .verify2fa .error {
    color: #e74c3c;
    font-size: 14px;
    margin-bottom: 20px;

  }
  
