.users{
    padding: 20px;
    background: #E5E5E5;
    width: 100%;
  
    .pageHeader {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      button {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 0;
        background-color: lighten($color: #1a2028, $amount: 10);
        font-size: 16px;
        &:hover {
          background-color: #1a2028;
        }
      }
    }
  }
  