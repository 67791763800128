.wrapper {
  display: flex;
}

.sideBarSection {
  border: none;
}

.content {
  margin-left: 280px; 
  transition: margin-left 0.3s;
  overflow: hidden;
  &.collapsed {
    margin-left: 80px; 
  }
}
