.UserInvitation {
    max-width: 400px;
    margin: 200px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    label {
      display: block;
      text-align: left;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    button {
      font-size: 18px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 6px;
      padding: 15px 20px;
      outline: none;
      background-color: #1A2028;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
  
      &:hover {
        background: #4a4a4a;
      }
  
      &:disabled {
        background-color: #d3d3d3;
        color: #808080;
        cursor: not-allowed;
      }
    }
  
    .formInvalid {
      input {
        border: 1px solid red;
      }
  
      span {
        color: red;
        font-size: 12px;
        display: block;
        margin-top: -5px;
      }
    }
  
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .inputWrapper input {
      width: 100%;
    }
    
    .eyeIcon {
    margin-bottom: 15px;
    margin-right: -10px;

      position: absolute;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 18px;
      &:hover {
        background: #ffffff00;
      }
    }
    
  }