body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
  background-color: #e5e5e5;
}
* {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  font-family: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0;
}
.defaultTable {
  .ant-table-wrapper {
    .ant-table-thead {
      > tr {
        &:first-child {
          th {
            &:last-child,
            &:first-child {
              border-radius: 0;
            }
          }
        }
        > th {
          font-family: inherit;
          background-color: #fff;
          border-bottom: 3px solid #e5e5e5;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: gray;
          border-radius: 0;
          
        }
      }
      .ant-table-cell {
        &::before {
          content: none !important;
        }
        &.ant-table-selection-column {
          padding: 3.5px;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        border-radius: 0;
        &:hover {
          td {
            cursor: pointer;
            background-color: #e4f1ff;
            [class*="filterIcon"] {
              visibility: visible;
            }
          }
        }
        @media (max-width: 1650px) {
          > td {
            max-width: 200px;  
            width: 200px;      
            white-space: nowrap;     
            overflow: hidden;             
            text-overflow: ellipsis; 
          }
        }
        
        > td {
          padding-top: 0;
          padding-bottom: 0;
          font-family: inherit;
          background-color: #fff;
          border-bottom: 3px solid #e5e5e5;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #000;
          border-radius: 0 !important;
          .tableText {
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            font-weight: 400 !important;
            ul,
            ol {
              list-style-type: none;
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
              font-weight: 400 !important;
              strong {
                font-weight: inherit;
              }
              img {
                display: none;
              }
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                overflow: hidden;
                font-weight: 400 !important;
              }
            }
          }
          .newCv {
            padding: 4px 10px;
            background-color: #5cdb95;
            color: #fff;
            border-radius: 3px;
          }
          .statusButton {
            padding: 8px 20px;
            border-radius: 4px;
            font-size: 16px;
          }
          
          .statusButton.contact {
            background-color: #4CAF50; 
            color: white;
          }
          
          .statusButton.newLead {
            background-color: #f44336; 
            color: white;
          }
          
          .cvText {
            border: 0;
            background: transparent;
            color: #5cb7db;
            font-weight: 600;
            text-decoration: underline;
            padding: 0;
            box-shadow: none;
            display: flex;
            align-items: center;
          }
          .status_no {
            width: 25px;
            height: 2px;
            background-color: #1a2028;
          }
          .vacancyStatus {
            svg {
              width: 25px;
              height: 25px;
            }
          }
          .tableActions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
              border: 0;
              outline: none;
              cursor: pointer;
              box-shadow: none;
              background-color: transparent;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              svg {
                min-width: 20px;
                min-height: 20px;
                width: 20px;
                height: 20px;
              }
              &:hover {
                background-color: #e5e5e5;
              }
            }
          }
        }
      }
      .ant-table-row-selected {
        td {
          background-color: #fafbff;
        }
      }
    }
  }
  .ant-pagination {
    align-items: center;
    .ant-pagination-prev,
    .ant-pagination-next {
      border: 1px solid #b8c4d6;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-pagination-prev {
      margin-right: 15px;
    }
    .ant-pagination-next {
      margin-left: 15px;
    }
    .ant-pagination-item {
      width: 32px;
      height: 32px;
      min-width: none;
      a {
        color: #1a2028;
        padding: 1px 0;
      }
      &.ant-pagination-item-active {
        background-color: #1a2028;
        border: none;
        border-radius: 50%;
        a {
          color: #fff;
        }
      }
    }
    .ant-pagination-options {
      margin-left: 20px;
      .ant-select-selector {
        height: 40px;
      }
    }
  }
  .ant-table-cell {
    &.ant-table-row-expand-icon-cell {
      height: 43px;
    }
    .label {
      font-family: inherit;
      background-color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: gray;
      margin-bottom: 10px;
      margin-top: 10px;
      border-radius: 0;
    }
  }
  .ant-table-expanded-row {
    .ant-table-cell {
      padding-top: 15px;
      padding-left: 50px;
    }
  }
  &.ordersTable {
    overflow-x: auto;
    .ant-table-cell {
      height: 43px;
    }
  }
}
.ant-notification-notice-icon-info {
  color: #ff0000 !important;
}
.ant-notification-notice-message {
  font-weight: 700;
  font-size: 18px !important;
}
.ant-notification-notice-description {
  font-size: 16px !important;
  font-weight: 500;
}
.deleteModal {
  max-width: 420px;
  .ant-modal-content {
    border-radius: 6px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    .ant-modal-close {
      svg {
        transition: 0.3s;
      }
      &:hover {
        svg {
          path {
            &:last-child {
              fill: #1a2028;
            }
          }
        }
      }
      .ant-modal-close-x {
        width: unset;
        height: unset;
        max-width: unset;
        max-height: unset;
        line-height: unset;
        margin-top: 7px;
        margin-right: 6px;
      }
    }
    
    .ant-modal-body {
      padding: 0;
      .ant-modal-confirm-body-wrapper {
        border-radius: 6px;
        overflow: hidden;
        .ant-modal-confirm-body {
          text-align: center;
          padding: 15px 30px;  
          background-color: #fff;
          .anticon {
            display: none;
          }
          .ant-modal-confirm-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: #1a2028;
            flex: initial;
            text-align: center;
            display: block;
            width: 100%;
          }
          .ant-modal-confirm-content {
            margin-inline-start: 0;
          }
        }
      }
      .ant-modal-confirm-btns {
        width: 100%;
        float: unset;
        margin: 0;
        padding: 15px 30px;
        background-color: #2c3e50; 
        display: flex;
        align-items: center;
        button {
          font-size: 16px;
          font-weight: 600;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          padding: 12px 30px;
          &:first-child {
            width: 100%;
            background-color: #fff;
            color: gray;
            border-radius: 6px;
          }
          &:last-child {
            width: 100%;
            background-color: #fa5745;
            color: #fff;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
label {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #1a2028;
  display: block;
  margin-bottom: 8px;
  white-space: nowrap;
}
input {
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  outline: none;
  padding-left: 17px;
  font-size: 16px;
  background-color: #f2f4f9;
  border-radius: 4px;
}
.ant-tabs {
  width: 100%;
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    background-color: #e5e5e5;
    &::before {
      content: none;
    }
  }
  .ant-tabs-nav-wrap {
    margin-top: 10px;
    justify-content: flex-end;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 15px 40px;
        margin-left: 4px;
        border-radius: 6px 6px 0 0;
        background-color: #767a7c;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        &.ant-tabs-tab-active {
          background-color: #1a2028;
          color: #fff;
          .ant-tabs-tab-btn {
            color: inherit;
          }
        }
      }
    }
  }
  .ant-tabs-content-holder {
    background-color: #fff;
    padding: 20px 40px;
  }
}
.error-msg {
  color: #ff0000;
}
.submit-btn {
  padding: 8px 12px !important;
  margin-left: auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: darken($color: #767a7c, $amount: 20);
  height: auto !important;
  &:hover {
    background-color: #1a2028 !important;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #1a2028 !important;
    border-color: #1a2028 !important;
  }
}
.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    border-color: #1a2028 !important;
    &::after {
      background-color: #1a2028 !important;
    }
  }
}
.fixedFooter {
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 300px;
  z-index: 2;
  width: calc(100% - 340px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  button {
    border: 0;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    background-color: #1a2028;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    font-size: 16px;
  }
  p {
    font-weight: 700;
  }
}
