.page {
    p {
      margin: 25px;
      color: #000;
      font-family: "Nunito Sans";
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      width: 526px;
    }
  
    .main {
      background-color: #FFFFFF;
      margin: 60px 120px;
      display: flex;
      min-width: 1007px;
      height: 800px;
  
      .first {
        border-right: 1px solid #E5E5E5;
        margin: 25px;
        height: 750px;
        display: flex;
        padding-right: 20px;
  
        p {
          width: 200px;
          color: #000;
          font-family: "Nunito Sans";
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          padding: 0;
          margin: 0;
        }
  
        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
        }
  
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
  
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          border-radius: 20px;
          transition: 0.4s;
        }
  
        input:checked + .slider {
          background-color: #4caf50;
        }
  
        .slider::before {
          position: absolute;
          content: '';
          height: 14px;
          width: 14px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          border-radius: 50%;
          transition: 0.4s;
        }
  
        input:checked + .slider::before {
          transform: translateX(20px);
        }
      }
     
      .second {
        width: 100%;
        margin: 25px;
        position: relative;
        span {
          color: #808080;
          font-family: "Nunito Sans";
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
  
        p {
          color: #000;
          width: 425px;
          font-family: "Nunito Sans";
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          padding: 0;
          margin-left: 0;
          margin-top: 10px;
        }
  
        .qr {
          width: 165px;
          height: 165px;
          margin-bottom: 60px;

        }
  
        .codeInput {
          display: flex;
          gap: 10px;
          margin-top: 20px;
  
          input {
            width: 40px;
            height: 50px;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            border: 1px solid #ccc;
            border-radius: 8px;
            outline: none;
            transition: all 0.3s ease-in-out;
            font-family: "Nunito Sans";
            &:focus {
              border-color: #4caf50;
              box-shadow: 0px 0px 5px rgba(76, 175, 80, 0.5);
            }
          }
        }
       
          
          .confirmButton {
              position: absolute;
              display: block;
              width: 150px;
              padding: 10px;
              border-radius: 4px;
              background: #454748;
              color: white;
              border: none;
              font-size: 16px;
              font-weight: bold;
              cursor: pointer;
              transition: 0.3s;
              bottom: 0;
              right: 0;
           
          
            &:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }
          }
          
          .message {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            color: #d32f2f;
          }
      }
      .third {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 130px;
        margin: 0px;
        border-radius: 8px;
        p{
            color: #000;
            text-align: center;
            font-family: "Nunito Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

      }
    }
  }
  