.vacancy {
  width: 100%;
  height: 100vh;
  padding: 20px 20px;
  overflow: hidden; 
  margin-bottom:50px;
  
    .itemsWrapper {
      width: 100%;
      margin-top: 25px;
      background-color: #fff;
      border-radius: 6px 0 6px 6px;
      max-height: calc(100vh - 80px); 
      overflow-y: auto; 
    label {
      display: block;
    }
    button {
      display: block;
    }
  }
  .outerTabsContent {
    width: 100%;
    padding: 20px 40px;
  }
}

